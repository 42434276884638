<template>
  <div class="ui grid container-5">
    <div class="image-div">
      <img class="people" src="@/assets/pd5.svg">
    </div>
    <div class="intro-content">
      <h1>Join <span>our growing community of
        TayoCash </span>Partners <span>&</span> Merchants</h1>
        <p>Contact us and we’ll be happy to help onboard you to become a TayoCash Partner or Merchant!</p>

      <router-link class="yellow" to="/helpcenter" v-scroll-to="'#ctctushc'">
        CONTACT US
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'content5'
}
</script>

<style scoped lang='scss'></style>
